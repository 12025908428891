<template>
  <div>
    <div class="flix-alert flix-alert-success">{{ $t("message.successfullSaved") }}</div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
